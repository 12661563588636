






































































































































































































































































































































































































































































































































































































































































































































































































































































































































// ::v-deep .el-select-dropdown__list {
// ::v-deep .el-select-dropdown__list {
// padding: 16px 0 !important;
//   background: #eee !important;
//   .el-select-dropdown__item {
//     background: #eee !important;
//   }
// }
.main_data_table_pagination_container {
  .data_table_pagination_footer {
    .showing_numbers_container {
      @media (min-width: 320px) and (max-width: 576px) {
        display: block !important;
        .card_numbers_text {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
